import React from "react"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          width: "100vw",
          height: "100vh"
        }}
      >
        <main>{children}</main>
      </div>
    </>
  )
}

export default Layout
